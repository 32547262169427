import { mapState } from "vuex"
import PAGEHEADER from '@/components/header.vue'
import * as api from './index-api'

import Vue from 'vue';
import { Button, Popup  } from 'vant';
Vue.use(Button)
.use(Popup)

export default {
	components: {
		PAGEHEADER
	},
	name: "Index",
	data() {
		return {
			password: '',
      username: '',
      reloadRouter: false,
		} 
	},
	computed: {
		...mapState({
      showPopup: state => state.showPopup,
      showThemeList: state => state.showThemeList,
      funList: state => state.funList,
      theme: state => state.theme,
      themeList: state => state.themeList,
		}),
  },
  mounted() {
    let t = JSON.parse(localStorage.getItem('ifm-theme'))
    if (t) this.themeChange(t)
  },

	methods: {
    /* 主题 */
    themeChange (t) {
      let b = document.getElementById('body')
      b.setAttribute('data-theme', t.themeData)
      localStorage.setItem('ifm-theme', JSON.stringify(t))

      this.$store.commit('THEME', t)  // 设置主题
      this.$store.commit('SHOW_POPUP', false) // 关闭弹出层
    },
    /* 菜单 */
    menuHandle (o) {
      let {
        show,
        showFlag,
        showFun,
        popShow
      } = o

      console.log(this.funList)

      showFun.call(this)
      this.$store.commit(show, showFlag)
      this.$store.commit('SHOW_POPUP', popShow)
    },
    /* 登录 */
		login () {
      let { username, password } = this

      let code = localStorage.getItem('ifm-code')
      
        if (username == "" && password == "") {
          this.$message({ message: "请输入用户名和密码" });
        } else if (username == "") {
          this.$message({ message: "请输入用户名" });
        } else if (password == "") {
          this.$message({ message: "请输入密码" });
        } else {

          /* 请求 */
          api.selectUserInfo({ cpassword: password, cusername: username, code }).then(data => {
              if (data.data.resultObject == -1) {
                  this.$message({ message: '用户名错误', type: 'warning' })
              } else if (data.data.resultObject == -2) {
                  this.$message({ message: '密码错误', type: 'warning' })
              } else {   
                  
                  /* 登录成功 */
                  this.$message({ message: '登录成功', type: 'success' })

                  let d = data.data.resultObject

                  localStorage.setItem('ifm-appToken', d.userToken)
                  localStorage.setItem('ifm-projectId', d.ctpId)
                  localStorage.setItem('ifm-userId', d.cuserId)

                  this.$store.commit('LOGIN_FLAG', true)

                  this.reload()
              }
          })
        }
    },
    /* 重载 */
    reload () {
      this.reloadRouter = true
      this.$nextTick(() => {
        this.reloadRouter = false
      })
    },

	},
}
