<template>
  <div class="page-header">
    <i
      :class="['iconfont', backFlag ? 'icon-fanhui' : '']"
      @click="back"
    ></i>

    <p class="title">
      <img  src="/img/page-logo.png" alt="">
      {{ title }}
    </p>
    <!-- <i class="iconfont icon-more"></i> -->

    <p class="iconfont p" @click="openPop">
      <i
        class="iconfont icon-more"
      ></i>
    </p>
  </div>
</template>
<script>
export default {

  computed: {
    title() {
      let {
        type
      } = this.$route.params
      // processing-list 特殊处理
      if (type == 'untreatedList') return '未处理报修'
      else if (type == 'beingProcessedList') return '处理中报修'
      if (type == 'processedList') return '已处理报修'

      return this.$route.name.split('-')[0];
    },

    // 如果name字段中有 -后缀
    backFlag () {
      return this.$route.name.split('-')[1]
    },
  },

  methods: {
    openPop() {
      this.$store.commit("SHOW_THEME_LIST", false);
      this.$store.commit("SHOW_POPUP", true);
    },

    back () {
      if (this.title == '添加报修') {
        this.$router.replace('/processing-center')
      } else {
        this.$router.back()
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/style/main.scss";

.page-header {
  min-height: 88px;
  background: #f0f2f6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 32px;

  /* theme */
  @include themeify {
    box-shadow: themed("shadow-primary");
    color: themed("text-color-primary");
    background: themed("bg-color-primary");
  }

  .back-to-center {
    font-size: 30px;
    margin-left: 20px;
  }

  .title {
    font-family: "微软雅黑";
    font-weight: 600;
    flex: 1;

    display: flex;
    align-items: center;
    justify-content: center;


    img {
      height: 40px;
      margin-right: 15px;
    }
  }

  .iconfont {
    width: 80px;
    font-size: 36px;
    text-align: center;

    &.p {
      font-size: 28px;
      font-weight: 600;
    }

    &.icon-more {
      font-size: 46px;
    }
  }
}
</style>
